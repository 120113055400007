<div class="general-container gap-24">
  <div class="center gap-12">
    <img src="/assets/images/onboarding/Success.svg" alt="Logo" />
    <div class="title">¡Te damos la bienvenida a DoctorGO!</div>
    <div class="text">
      Comienza a disfrutar ya de todos nuestros servicios.
    </div>
  </div>
  <div class="opt-container">
    <div class="opt-item">
      <div class="first-line">
        <div class="opt-icon">
          <img src="/assets/images/onboarding/Telemedicine.svg" alt="Logo" />
        </div>
        <div class="opt-title">
          TELEMEDICINA 24H
        </div>
      </div>
    </div>
    <div class="opt-content">
      Realiza consultas médicas sin esperas en cualquier momento mediante chat y Videoconsultas.
    </div>

    <div class="opt-item">
      <!-- <div class="first-line">
        <div class="opt-icon">
          <img src="/assets/images/onboarding/Pharmacy.svg" alt="Logo" />
        </div>
        <div class="opt-title">
          RECETAS ELECTRÓNICAS Y TELEFARMACIA
        </div>
      </div>
      <div class="opt-content">
        Recibe tus recetas directamente en tu móvil, con validez en todas las farmacias de España.
      </div> -->
      <div class="first-line">
        <div class="opt-item">
          <div class="opt-icon">
            <img src="/assets/images/onboarding/Fruit.svg" alt="Logo" />
          </div>
        </div>
        <div class="opt-title">
          PLANES DE NUTRICIÓN Y FITNESS
        </div>
      </div>
      <div class="opt-content">
        Nuestros coaches diseñarán especialmente para ti programas personalizados.
      </div>
    </div>
  </div>
  <app-sign-up-footer buttonText="HACER MI PRIMERA CONSULTA" [buttonValid]="true"
    (buttonClick)="continueDebouncer.next()">
  </app-sign-up-footer>
</div>
