import { RouterModule, Routes } from '@angular/router';

import { BookingComponent } from './components/universal/booking/booking.component';
import { BreadcrumbResolver } from './resolvers/BreadcrumbResolver.service';
import { ChatSpeciality66Component } from './components/universal/chat-speciality66/chat-speciality66.component';
import { ClientesVitaldentComponent } from './components/clientes-vitaldent/clientes-vitaldent.component';
import { ContactComponent } from './components/universal/contact/contact.component';
import { Covid19Component } from './components/universal/covid19/covid19.component';
import { EntrenavirtualComponent } from './components/universal/entrenavirtual/entrenavirtual.component';
import { FormWatchComponent } from './components/form-watch/form-watch.component';
import { InvitationCodeComponent } from './components/universal/invitation-code/invitation.component';
import { MedicalHistoryComponent } from './components/universal/medical-history/medical-history.component';
import { MyAccountComponent } from './components/universal/my-account/my-account.component';
import { NgModule } from '@angular/core';
import { ProfileComponent } from './components/universal/profile/profile.component';
import { QrWatchComponent } from './components/qr-watch/qr-watch.component';
import { QsComponent } from './components/universal/qs/qs.component';
import { SorteoComponent } from './components/sorteo/sorteo.component';
import { TelefarmaciaComponent } from './components/universal/telefarmacia/telefarmacia.component';
import { UniversalAppComponent } from './components/universal/universal-app/universal-app.component';

const routes: Routes = [


  {
    path: '',
    data: {
      title: 'App Salud Online',
    },
    loadChildren: () =>
      import('./components/online-health-v2/online-health-v2.module').then(
        m => m.OnlineHealthV2Module
      )


  },
{
  path: 'eu',
  loadChildren: () =>
    import('./components/online-health-v2-eusk/online-health-v2.module').then(
      m => m.OnlineHealthV2Module
    )
},
  // {
  //   path: 'test-anticuerpos-covid',
  //   loadChildren: () =>
  //     import('./components/health-test/health-test.module').then(
  //       m => m.HealthTestModule
  //     )
  // },

  // {
  //   path: 'telefarmacia-y-receta-electronica',
  //   data: {
  //     title: 'Receta electrónica y telefarmacia'
  //   },
  //   loadChildren: () =>
  //     import('./components/telepharmacy/telepharmacy.module').then(
  //       m => m.TelepharmacyModule
  //     )
  // },

  {
    path: 'receta-electronica',
    data: {
      title: 'Receta electrónica'
    },
    loadChildren: () =>
      import('./components/telepharmacy/telepharmacy.module').then(
        m => m.TelepharmacyModule
      )
  },
  
  {
    path: 'alergologo',
    data: {
      title: 'Alergología',
    },
    loadChildren: () =>
      import('./components/specialities/allergology-v2/allergology-v2.module').then(
        m => m.AllergologyV2Module
      )
  },
  {
    path: 'medico-general-online',
    data: {
      title: 'Médico General',
    },
    loadChildren: () =>
      import('./components/specialities/general-medicine/general-medicine.module').then(
        m => m.GeneralMedicineModule
      )
  },
  {
    path: 'cardiologo',
    data: {
      title: 'Cardiología',
    },
    loadChildren: () =>
      import('./components/specialities/cardiology-v2/cardiology-v2.module').then(
        m => m.CardiologyV2Module
      )
  },
  {
    path: 'dermatologo',
    data: {
      title: 'Dermatología',
    },
    loadChildren: () =>
      import('./components/specialities/dermatology-v2/dermatology-v2.module').then(
        m => m.DermatologyV2Module
      )
  },
  {
    path: 'entrenador-personal-online',
    data: {
      title: 'Entrenamiento Personal',
    },
    loadChildren: () =>
      import('./components/specialities/personal-training-v2/personal-training-v2.module').then(
        m => m.PersonalTrainingV2Module
      )
  },
  {
    path: 'entrenamiento-virtual',
    data: {
      title: 'Entrenamiento Virtual',
    },
    loadChildren: () =>
      import('./components/specialities/virtual-training/virtual-training.module').then(
        m => m.VirtualTrainingModule
      )
  },
  {
    path: 'ginecologo',
    data: {
      title: 'Ginecología',
    },
    loadChildren: () =>
      import('./components/specialities/gynaecology-v2/gynaecology-v2.module').then(
        m => m.GynaecologyV2Module
      )
  },
  {
    path: 'nutricionista',
    data: {
      title: 'Nutricionista',
    },
    loadChildren: () =>
      import('./components/specialities/nutrition-v2/nutrition-v2.module').then(
        m => m.NutritionV2Module
      )
  },
  {
    path: 'pediatra',
    data: {
      title: 'Pediatría',
    },
    loadChildren: () =>
      import('./components/specialities/paediatrics-v2/paediatrics-v2.module').then(
        m => m.PaediatricsV2Module
      )
  },
  {
    path: 'psicologo',
    data: {
      title: 'Psicología',
    },
    loadChildren: () =>
      import('./components/specialities/psychology-v2/psychology-v2.module').then(
        m => m.PsychologyV2Module
      )
  },
  {
    path: 'sexologo',
    data: {
      title: 'Sexología',
    },
    loadChildren: () =>
      import('./components/specialities/sexology-v2/sexology-v2.module').then(
        m => m.SexologyV2Module
      )
  },
  // {
  //   path: 'analitica-sangre-y-orina',
  //   data: {
  //     title: 'Analítica completa de sangre y orina',
  //   },
  //   loadChildren: () =>
  //     import('../../components/specialities/analytics/analytics.module').then(
  //       m => m.AnalyticsModule
  //     )
  // }



  {
    path: 'receta-medica-electronica',
    loadChildren: () =>
      import(
        './components/services/electronic-prescription/electronic-prescription.module'
      ).then((m) => m.ElectronicPrescriptionModule),
  },
  {
    path: 'ayuda',
    loadChildren: () =>
      import('./components/faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'cookies',
    loadChildren: () =>
      import('./components/cookies/cookies.module').then((m) => m.CookiesModule),
  },
  {
    path: 'legal',
    loadChildren: () =>
      import('./components/legal/legal.module').then((m) => m.LegalModule),
  },

  {
    path: 'informacion-legal',
    data: {
      title: 'Legales',
    },
    loadChildren: () =>
      import('./components/legal-information/legal-information.module').then((m) => m.LegalInformationModule),
  },

  {
    path: 'primeros-pasos-watch',
    component: QrWatchComponent
  },

  {
    path: 'reloj-teleasistencia',
    component: FormWatchComponent
  },

  // UNIVERSAL LINKS:
  {
    path: 'app',
    component: UniversalAppComponent,
  },
  {
    path: 'app/covid19',
    component: Covid19Component,
  },
  {
    path: 'app/chat',
    component: ChatSpeciality66Component,
  },
  {
    path: 'app/medicalhistory',
    component: MedicalHistoryComponent,
  },
  {
    path: 'app/qs',
    component: QsComponent,
  },
  {
    path: 'app/contact',
    component: ContactComponent,
  },
  {
    path: 'app/entrenavirtual',
    component: EntrenavirtualComponent,
  },
  {
    path: 'app/telefarmacia',
    component: TelefarmaciaComponent,
  },
  {
    path: 'app/booking',
    component: BookingComponent,
  },
  {
    path: 'app/myaccount',
    component: MyAccountComponent,
  },
  {
    path: 'app/profile',
    component: ProfileComponent,
  },

  {
    path: 'app/invitationcode',
    component: InvitationCodeComponent,
  },

  {
    path: 'sign-up-meeting/:token',
    loadChildren: () =>
      import('./components/sign-up-meeting/sign-up-meeting.module').then((m) => m.SignUpMeetingModule),
  },

  {
    path: 'sign-up-meeting',
    loadChildren: () =>
      import('./components/sign-up-meeting/sign-up-meeting.module').then((m) => m.SignUpMeetingModule),
  },

  {
    path: 'sorteos',
    loadChildren: () =>
      import(
        './components/sorteo/sorteo.module'
      ).then((m) => m.SorteoModule),
  },

  {
    path: 'contacto',
    loadChildren: () =>
      import(
        './components/contact/contact.module'
      ).then((m) => m.ContactModule),
  },

  /*{
    path: 'mascotas',
    loadChildren: () =>
      import(
        './components/pets/pets.module'
      ).then((m) => m.PetsModule),
  },*/

  {
    path: 'privacidad-dkv',
    loadChildren: () =>
      import('./components/privacidad-dkv/privacidad-dkv.module').then(
        m => m.PrivacidadDkvModule,
      )
  },

  {
    path: 'webviews/unsubscribe/:token',
    loadChildren: () =>
      import('../app/components/sign-up-meeting/components/webview-unsubscribe/webview-unsubscribe.module').then(
        m => m.WebviewUnsubscribeModule,
      )
  },

  {
    path: 'webviews/unsubscribe',
    loadChildren: () =>
      import('../app/components/sign-up-meeting/components/webview-unsubscribe/webview-unsubscribe.module').then(
        m => m.WebviewUnsubscribeModule,
      )
  },

  // UNIVERSAL LINKS Ends:

  {
    path: '**',
    pathMatch: 'full',
    loadChildren: () =>
      import('./components/not-found-page/not-found-page.module').then(
        (m) => m.NotFoundPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
